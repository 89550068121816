.upperheader {
  background: #2d4e9d;
  color: white;
  height: 40px;
  width: 100%;
  border-bottom: 0.001rem solid #435e9f;
  @media (max-width: 768px) {
    display: none;
  }
  &__container {
    display: flex;
    justify-content: space-between;
    height: 100%;
    align-items: center;
    &__right {
      display: flex;
      &__span {
        margin-left: 27px;
        @media (max-width: 992px) {
        }
      }
    }
    @media (max-width: 992px) {
      .home__phone {
        display: none;
      }
    }
    &__left {
      font-style: normal;
      font-weight: 400;
      font-size: 14px;
      &__span {
        margin-right: 24px;
        @media (max-width: 992px) {
          margin-right: 14px;
          .home__phone {
            display: none;
          }
        }
      }
    }
  }
}
.header__sticky {
  background-color: #2d4e9d !important;
  height: 6rem;
  transition-property: all;
  transition-timing-function: cubic-bezier(0.4, 0, 0.2, 1);
  transition-duration: 500ms;
  position: fixed !important;
  top: 0 !important;
}

.header {
  width: 100%;
  height: 60px;
  background: transparent;
  color: white;
  position: absolute;
  z-index: 99;
  top: 40px;

  // @media (max-width: 768px) {
  //   position: fixed;
  //   top: 0;
  // }
  &__container {
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: space-between;

    &__nav {
      @media (max-width: 768px) {
        display: none;
      }
      &__ul {
        padding: 0;
        margin: 0;
        display: flex;

        &__li {
          list-style: none;
          text-transform: capitalize;
          margin-left: 56px;
          display: flex;
          align-items: center;
          position: relative;
          @media (max-width: 1200px) {
            margin-left: 26px;
          }
          @media (max-width: 992px) {
            margin-left: 16px;
            // font-size: 12px;
          }
          .link__span {
            transition: 200ms ease-in;
          }
          &:nth-child(-n + 5):hover {
            .link__span {
              color: #be9140;
            }
            &::after {
              width: 100%;
            }
          }
          &::after {
            content: "";
            position: absolute;
            bottom: 0;
            left: 0;
            height: 2px;
            width: 0%;
            background: #be9140;
            transition: 200ms ease-in;
          }

          &__button {
            padding: 8px 24px;
            height: 42px;
            background: #c5ac52;
            font-weight: 500;
            font-size: 16px;
            color: white;
            border: none;
            width: max-content;

            @media (max-width: 992px) {
              padding: 8px 14px;
            }
          }
          &:hover {
            .li__lang {
              position: absolute;
              top: 100%;
              right: 0;
              display: block;
            }
          }
          .li__lang {
            position: absolute;
            top: 100%;
            display: none;
          }
        }
      }
    }

    &__logo {
      width: 54px;
      height: 100%;
      margin-top: 10px;
      img {
        object-fit: cover;
        object-position: center;
        width: 100%;
        width: 54px;
        height: 50px;
      }
    }
  }
}
.menudrawer {
  background: #2d4e9d;
  padding-top: 68px;
  height: 497px;
  &__container {
    &__upper {
      color: white;
      display: flex;
      flex-direction: column;
      align-items: center;
      border-bottom: 1px solid #435e9f;
      &__langs {
        margin-top: 8px;
        padding-bottom: 13px;
        &__span {
          padding: 10px;
          font-style: normal;
          font-weight: 500;
          font-size: 16px;
        }
      }
      .icons__href {
        padding: 10px;
      }
      &__span {
        margin-bottom: 8px;
        text-transform: capitalize;
        font-style: normal;
        font-weight: 400;
        font-size: 12px;
      }
    }
    &__down {
      display: flex;
      justify-content: center;
      padding-top: 34px;
      &__ul {
        padding: 0;
        margin: 0;
        text-align: center;
        &__li {
          list-style: none;
          padding-bottom: 12px;
          font-style: normal;
          font-weight: 400;
          font-size: 16px;
          text-transform: capitalize;
          &__button {
            padding: 8px 24px;
            height: 42px;
            background: #c5ac52;
            font-weight: 500;
            font-size: 16px;
            color: white;
            border: none;
          }
        }
      }
    }
  }
}
.headerlangs {
  background: white;
  &__ul {
    margin: 0;
    padding: 20px;
    &__li {
      list-style: none;
      padding-bottom: 8px;
      color: black;
      cursor: pointer;
    }
  }
}
.headerdown {
  height: 382px;
  width: 100%;
  background: #2d4e9d;
  position: relative;
  &__title {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    text-align: center;
    h1 {
      font-family: "Merriweather";
      font-style: normal;
      font-weight: 700;
      font-size: 50px;
      color: white;
      text-transform: capitalize;
      @media (max-width: 992px) {
        font-size: 28px;
      }
    }
    p {
      font-style: normal;
      font-weight: 400;
      font-size: 18px;
      color: white;
      @media (max-width: 992px) {
        font-size: 16px;
      }
    }
  }
  &__cover {
    margin: 0 auto;
    width: 791px;
    height: 100%;
    @media (max-width: 992px) {
      width: 100%;
    }
    img {
      width: 100%;
      height: 100%;
      object-fit: cover;
      object-position: center;
    }
  }
}
