.notfound__parent {
  position: fixed;
  top: 0;
  right: 0;
  width: 100%;
  background: white;
  height: 100vh;
  z-index: 999;
  display: flex;
  justify-content: center;
  align-items: center;
  .cus-row {
    width: 60%;
    margin: 0 auto;
    text-align: center;
    @media (max-width: 768px) {
      width: 100%;
    }
    // padding: 124px 0;
    p {
      font-weight: 400;
      font-size: 16px;
      color: #474747;
    }
    .btns {
      display: flex;
      justify-content: center;
      gap: 24px;
      @media (max-width: 768px) {
        flex-direction: column;
      }

      .new-btn {
        color: #c5ac52;
        border: 2px solid #c5ac52;
        text-decoration: none;
        padding: 12px 24px;
        transition: 200ms all ease-in;

        &:hover {
          background-color: #c5ac52;
          color: #fff;
        }
      }
      .home {
        color: #fff;
        background-color: #c5ac52;
        text-decoration: none;
        padding: 12px 24px;
        transition: 200ms all ease-in;
        &:hover {
          background-color: #be9140;
        }
      }
    }
  }
}
