.advocatecard__slider__card__row {
  justify-content: space-between;
  margin: 60px 0;
  padding: 0;
  text-align: center;
  .team__links {
    text-decoration: none;
    color: #474747;
  }
  @media (max-width: 768px) {
    width: 100%;
    justify-content: center;
  }
}
.advocatecard__slider__card {
  padding: 0;
  height: 402px;
  width: 382px !important;
  background: #fbfbfb;
  border: 2px solid #be9140;
  margin-bottom: 16px;
  justify-content: space-between;

  @media (max-width: 768px) {
    width: 322px !important;
  }
  &:hover {
    .advocatecard__slider__card__image {
      padding: 0;
      &__ul {
        opacity: 1;
        visibility: visible;
      }
    }
  }

  &__image {
    transition: 200ms;
    height: 334px;
    overflow: hidden;
    padding: 12px 12px 0 12px;
    position: relative;

    &__ul {
      position: absolute;
      bottom: 0;
      padding-left: 16px;
      transition: 200ms ease;
      opacity: 0;
      visibility: hidden;
      li {
        font-family: "Poppins";

        list-style: none;
        text-align: start;
        font-style: normal;
        font-weight: 400;
        font-size: 16px;
        color: #fff;
        img {
          width: 20px;
          object-fit: contain;
          margin-right: 12px;
        }
      }
    }
    &:hover {
      &::after {
        background: #2d4f9d40;
      }
    }
    &::after {
      content: "";
      position: absolute;
      top: 0;
      right: 0;
      width: 100%;
      height: 100%;
      transition: 200ms ease;
    }
    img {
      transition: 200ms ease;

      width: 100%;
      height: 100%;
      object-fit: cover;
      object-position: center;
    }
  }
  &__name {
    margin-top: 21px;
    font-family: "Merriweather";
    font-style: normal;
    font-weight: 700;
    font-size: 18px;
    text-transform: capitalize;
  }
}
