footer {
  background-color: #2d4e9d;
  #first {
    padding: 40px 0;
    .custom-container {
      display: flex;
      justify-content: space-between;
      #logo {
        img {
          width: 161.96px;
          height: 150px;
        }
      }
      #footer-item {
        h5 {
          font-weight: 700;
          font-size: 16px;
          line-height: 28px;
          color: #fff;
          padding: 0 0 0px 0;
        }
        ul {
          list-style: none;
          margin: 0;
          padding: 0;
          li {
            display: flex;
            color: #fff;
            a {
              text-decoration: none;
              color: #fff;
              font-weight: 400;
              font-size: 16px;
              line-height: 25.6px;
              &::after {
                content: " ";
                display: block;
                width: auto;
                height: 1px;
                background-color: #fff;
                opacity: 0;
                transition: 500ms all ease-in;
              }
              &:hover {
                &::after {
                  opacity: 0.5;
                }
              }
            }
          }
        }
      }
      $breakpoint-tablet: 768px;
      @media (max-width: $breakpoint-tablet) {
        display: block;

        #logo {
          display: flex;
          align-items: center;
          justify-content: center;
        }
        #footer-item {
          padding: 15px 0;
        }
      }
      @media (max-width: 970px) {
        gap: 20px;
      }
    }
  }
  #second {
    hr {
      width: 100%;
      color: #fff;
    }
    .custom-container {
      display: flex;
      flex-direction: column;
      align-items: center;
      text-align: center;
      p {
        color: #fff;
        padding: 0;
        font-weight: 400;
        font-size: 14px;
        line-height: 22.4px;
      }
    }
  }
}
