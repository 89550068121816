.wrapper {
  padding: 60px 0;
  .container {
    & > .contact__row {
      & > .info {
        .map {
          width: 100%;
          iframe {
            width: 100%;
            height: 450px;
            border: 4px solid #be9140;
          }
        }
        & > .contact-info {
          & > .contact__row {
            & > .col-lg-6 {
              margin: 24px 0;
              display: flex;
              img {
                width: 32px;
                height: 32px;
              }
              & > div {
                padding: 0 0 0 17px;
                h5 {
                  font-weight: 700;
                  font-family: "Merriweather";
                  font-size: 16px;
                  color: #be9140;
                  margin: 0;
                }
                p {
                  font-weight: 400;
                  font-size: 16px;
                  font-family: "Poppins";
                  color: #474747;
                  margin: 0;
                }
              }
            }
          }
        }
        @media (max-width: 991px) {
          display: flex;
          flex-direction: column-reverse;
        }
      }
      .form {
        h4 {
          color: #be9140;
          font-weight: 700;
          font-family: "Merriweather";
          font-size: 22px;
        }
        p {
          font-family: "Poppins";
          font-weight: 400;
          font-size: 16px;
          color: #474747;
        }
        form {
          height: 100%;
          & > .contact__row {
            & > div {
              & > div {
                display: flex;
                flex-direction: column;
                & > label {
                  font-size: 14px;
                  font-weight: 700;
                  font-family: "Merriweather";
                  color: #474747;
                  margin-top: 20px;
                }
                & > input {
                  border: 1px solid #a3a3a3;
                  height: 44px;
                  padding: 9px 0 9px 16px;
                  margin-top: 8px;
                  background-color: #fbfbfb;
                  outline: none;
                  transition: 200ms all ease-in;
                  &::placeholder {
                    font-size: 16px;
                    font-weight: 400;
                    color: #a3a3a3;
                  }
                  &:focus {
                    border: 0;
                    outline: 2px solid #c5ac52;
                  }
                }
                & > select {
                  border: 1px solid #a3a3a3;
                  height: 44px;
                  padding: 9px 0 9px 16px;
                  margin-top: 8px;
                  background-color: #fbfbfb;
                  outline: none;
                  font-size: 16px;
                  font-weight: 400;
                  color: #a3a3a3;
                  &:focus {
                    outline: 2px solid #c5ac52;
                  }
                  & > option {
                    border: 1px solid #c5ac52;
                  }
                }
                & > textarea {
                  margin: 16px 0 0 0;
                  padding: 8px 0 0 16px;
                  outline: 0;
                  transition: 200ms all ease-in;
                  background-color: #fbfbfb;
                  border: 1px solid #a3a3a3;
                  height: 132px;
                  &:focus {
                    border: 0;
                    outline: 2px solid #c5ac52;
                  }
                  &:active {
                    border: 0;
                    outline: 2px solid #c5ac52;
                  }
                }
                & > button {
                  margin: 32px 0 0 0;
                  background-color: #c5ac52;
                  border: 0;
                  padding: 12px 60px;
                  color: #fff;
                  font-size: 16px;
                  font-weight: 500;
                  font-family: "Poppins";
                }
              }
            }
            & > .button {
              display: flex;
              justify-content: end;
              & > div {
                @media (max-width: 991px) {
                  width: 100%;
                }
              }
            }
          }
        }
      }
      @media (max-width: 991px) {
        flex-direction: column-reverse;
        gap: 20px;
      }
    }
  }
}
