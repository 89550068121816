main {
  padding: 40px 0;
  .custom-container {
    .row {
      justify-content: center;
      .loader {
        width: 100vw;
        height: 100vh;
        position: absolute;
        left: 0;
        top: 0;
        z-index: 10;
        padding: 0;
        object-fit: cover;
      }
      .col-lg-4 {
        padding: 10px 12px;
        a {
          text-decoration: none;
        }
        .custom-card {
          border: 2px solid #be9140;
          text-align: center;
          height: 313px;
          // display: flex;
          // flex-direction: column;
          // align-items: center;
          // justify-content: space-evenly;
          padding: 0 18px;
          cursor: pointer;
          transition: 200ms all ease-in;
          & > div {
            width: 80px;
            height: 80px;
            margin: 48px auto 0;
            padding: 10px;
            background-color: #fff;
            border-radius: 50%;
            img {
              width: 100%;
              height: 100%;
              // filter: invert(100%);
            }
          }
          h5 {
            margin: 24px auto 0;
            font-weight: 700;
            font-size: 18px;
            line-height: 28.8px;
            color: #262626;
            font-family: "Merriweather";
            transition: 200ms all ease-in;
          }
          p {
            margin: 8px auto 0;
            font-weight: 400;
            font-size: 16px;
            line-height: 25.6px;
            color: #474747;
            padding: 0;
            transition: 200ms all ease-in;
          }
          &:hover {
            background-color: #be9140;
            p {
              color: #fff;
            }
            h5 {
              color: #fff;
            }
          }
        }
      }
    }
  }
}
